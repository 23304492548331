<template>
  <div class="image-cropper">
    <vueCropper
      ref="cropper"
      :img="imageBase64"
      :outputSize="outputSize"
      :outputType="outputType"
      :info="info"
      :full="full"
      :canScale="canScale"
      :canMove="canMove"
      :canMoveBox="canMoveBox"
      :original="original"
      :autoCrop="autoCrop"
      :fixed="fixed"
      :fixedNumber="fixedNumber"
      :centerBox="centerBox"
      :infoTrue="infoTrue"
      :fixedBox="fixedBox"
      :autoCropWidth="autoCropWidth"
      :autoCropHeight="autoCropHeight"
    ></vueCropper>
  </div>
</template>

<script>
import 'vue-cropper/dist/index.css'
import { VueCropper } from 'vue-cropper'

export default {
  name: 'ImageCropper',
  components: {
    VueCropper
  },
  props: {
    info: {
      // 裁剪框的大小信息
      type: Boolean,
      default: true
    },
    outputSize: {
      // 裁剪生成图片的质量
      type: Number,
      default: 1
    },
    outputType: {
      // 裁剪生成图片的格式
      type: String,
      default: 'jpeg'
    },
    canScale: {
      // 图片是否允许滚轮缩放
      type: Boolean,
      default: false
    },
    autoCrop: {
      // 是否默认生成截图框
      type: Boolean,
      default: true
    },
    autoCropWidth: {
      // 默认生成截图框宽度
      type: Number,
      default: 1003
    },
    autoCropHeight: {
      // 默认生成截图框高度
      type: Number,
      default: 620
    },
    fixedBox: {
      // 固定截图框大小 不允许改变
      type: Boolean,
      default: false
    },
    fixed: {
      // 是否开启截图框宽高固定比例
      type: Boolean,
      default: true
    },
    fixedNumber: {
      // 截图框的宽高比例
      type: Array,
      default: () => {
        return [100, 62]
      }
    },
    full: {
      // 是否输出原图比例的截图
      type: Boolean,
      default: false
    },
    canMove: {
      // 上传图片是否可以移动
      type: Boolean,
      default: true
    },
    canMoveBox: {
      // 截图框能否拖动
      type: Boolean,
      default: true
    },
    original: {
      // 上传图片按照原始比例渲染
      type: Boolean,
      default: false
    },
    centerBox: {
      // 截图框是否被限制在图片里面
      type: Boolean,
      default: true
    },
    infoTrue: {
      // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageBase64: '',
      imageName: ''
    }
  },
  methods: {
    drawCoverImage(image) {
      const reader = new FileReader() // 实例化文件读取对象
      reader.readAsDataURL(image) // 将文件读取为 DataURL,也就是base64编码
      const that = this
      reader.onload = function (ev) {
        // 文件读取成功完成时触发
        that.imageName = image.name
        that.imageBase64 = ev.target.result // 获得文件读取成功后的DataURL,也就是base64编码
      }
    },
    // base64转图片
    dataURLtoFile(base64) {
      const arr = base64.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bStr = atob(arr[1])
      let n = bStr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bStr.charCodeAt(n)
      }
      return new File([u8arr], this.imageName, { type: mime })
    },
    getCutImage() {
      this.$refs.cropper.getCropData((data) => {
        this.$emit('cutImage', this.dataURLtoFile(data))
      })
    }
  }
}
</script>

<style scoped>
.image-cropper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #8a8f97;
}
</style>
