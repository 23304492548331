<template>
  <div class="upload-cropper">
    <el-upload
      drag
      list-type="picture"
      :auto-upload="false"
      :show-file-list="false"
      :on-change="selectImage"
    >
      <el-image
        v-if="imageShowUrl !== '' && imageShowUrl != null"
        fit="fill"
        style="width: 100%; height: 100%"
        lazy
        :src="imageShowUrl"
      ></el-image>
      <div v-else>
        <el-icon class="el-icon--upload">
          <upload-filled />
        </el-icon>
        <div class="el-upload__text">
          拖拽图片至此
          <em>点击上传</em>
        </div>
      </div>
      <template #tip>
        <div class="el-upload__tip">
          仅限上传 {{ imageFormat.join(' / ') }} 格式的图片，且不超过 {{ imageSize }} M
        </div>
      </template>
    </el-upload>
    <el-dialog v-model="showImageCropper" title="图片裁剪" width="60%" top="5vh">
      <ImageCropper class="cut-image" @cutImage="cutImage" ref="imageCropper"></ImageCropper>
      <template #footer>
        <el-button @click="showImageCropper = false">
          <icon icon-name="CloseBold" icon-size="20" icon-title="放 弃"></icon>
        </el-button>
        <el-button type="primary" @click="getCutImage">
          <icon icon-name="Select" icon-size="20" icon-title="确 定"></icon>
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import icon from '@/components/icon'
import ImageCropper from '@/components/upload/ImageCropper'
import { UploadFilled } from '@element-plus/icons-vue'

export default {
  name: 'UploadCropper',
  components: {
    icon,
    ImageCropper,
    'upload-filled': UploadFilled
  },
  props: {
    imageFormat: {
      // 图片格式
      type: Array,
      default: () => {
        return ['gif', 'jpg', 'jpeg', 'png']
      }
    },
    imageSize: {
      // 图片大小
      type: Number,
      default: 2
    },
    imageShowUrl: {
      // 图片地址
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showImageCropper: false
    }
  },
  methods: {
    selectImage(file) {
      const format = file.raw.type.indexOf('image') !== -1
      if (!format) {
        this.$message({
          type: 'error',
          message: '仅限上传 ' + this.imageFormat.join(' / ') + ' 格式的图片'
        })
        return
      }
      this.showImageCropper = true
      this.$nextTick(() => {
        this.$refs.imageCropper.drawCoverImage(file.raw)
      })
    },
    getCutImage() {
      this.$refs.imageCropper.getCutImage()
    },
    cutImage(image) {
      const format = image.type.indexOf('image') !== -1
      if (!format) {
        this.$message({
          type: 'error',
          message: '仅限上传 ' + this.imageFormat.join(' / ') + ' 格式的图片'
        })
        return
      }
      const sizeLimit = image.size / 1024 / 1024 < this.imageSize
      if (!sizeLimit) {
        this.$message({ type: 'error', message: '上传图片仅限 ' + this.imageSize + 'M' })
        return
      }
      this.$emit('uploadImage', image)
      this.showImageCropper = false
    }
  },
  mounted() {}
}
</script>

<style scoped>
.upload-cropper {
  display: flex;
  justify-content: space-around;
}

.upload-cropper .cut-image {
  height: 600px;
  width: 100%;
  margin: 0 auto;
}
</style>
